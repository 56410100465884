<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="9"
          class="total-profit-chart-col"
        >
          <v-card-title class="px-0 pt-0">
            Snowflake Consumption ({{ dateRangeFilterSelection.name }})
          </v-card-title>
          <vue-apex-charts
            id="total-profit-chart"
            height="320"
            :options="chartOptions"
            :series="chartData"
          ></vue-apex-charts>
        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <v-card elevation="0">
            <!-- Title/Header -->
            <v-card-title class="align-start pt-0 flex-nowrap">
              <div>
                <p class="mb-0 font-weight-bold text-2xl">
                  $482.85k
                </p>
                <small class="text--secondary text-xs text-no-wrap">Last month balance $234.40k</small>
              </div>
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                class="me-n7 mt-n1"
              >
                <v-icon size="22">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text class="pb-0 pt-5">
              <!-- List -->
              <v-list
                two-line
                subheader
              >
                <!-- List Item: Profit -->
                <v-list-item class="pa-0">
                  <v-list-item-avatar
                    class="v-avatar-light-bg success--text justify-center"
                    size="40"
                    rounded
                  >
                    <v-icon
                      size="30"
                      color="success"
                    >
                      {{ icons.mdiTrendingUp }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-semibold">
                      $48,568.20
                    </v-list-item-title>
                    <v-list-item-subtitle>Total Profit</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!-- List Item: Income -->
                <v-list-item class="pa-0">
                  <v-list-item-avatar
                    class="v-avatar-light-bg primary--text justify-center"
                    size="40"
                    rounded
                  >
                    <v-icon
                      size="30"
                      color="primary"
                    >
                      {{ icons.mdiCurrencyUsd }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-semibold">
                      $38,453.25
                    </v-list-item-title>
                    <v-list-item-subtitle>Total Income</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <!-- List Item: Expense -->
                <v-list-item class="pa-0">
                  <v-list-item-avatar
                    color="primary"
                    class="v-avatar-light-bg secondary--text justify-center"
                    size="40"
                    rounded
                  >
                    <v-icon
                      size="30"
                      color="secondary"
                    >
                      {{ icons.mdiChartBar }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-semibold">
                      $2,453.45
                    </v-list-item-title>
                    <v-list-item-subtitle>Total Expense</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!-- Action Button -->
              <v-btn
                block
                color="primary"
              >
                View Report
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { colorLight, formatNumbers } from '@/functions'
import store from '@/store'
import { getVuetify } from '@core/utils'
import { kFormatter } from '@core/utils/filter'
// eslint-disable-next-line object-curly-newline
import { mdiChartBar, mdiCurrencyUsd, mdiDotsVertical, mdiTrendingUp } from '@mdi/js'
import { computed, inject } from '@vue/composition-api'
import _ from 'lodash'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  setup(props, context) {
    const dateRangeFilterSelection = computed(() => store.getters.dateRangeFilter)
    const getUserConsumption = computed(
      () => store.getters[`snowflakeData/snowflakeUserConsumption${dateRangeFilterSelection.value.objectType}`],
    )

    // Get Filter Range Days
    const dateRangeFilterDays = computed(() => store.getters.dateRangeFilterDays)
    const userId = inject('userId')

    // Get Execution and Consumption Data and Group by Date
    // Could Add Filtering Later
    const executions = computed(() => {
      const userConsumption = getUserConsumption.value.filter(f => f.user === userId.value)
      console.log('UPDATING DATA', userConsumption)
      console.log('DATE RANGE', dateRangeFilterSelection.value)
      const successfulExecutions = []
      const failedExecutions = []
      const duration = []
      dateRangeFilterDays.value.block.forEach(timestamp => {
        console.log('TIMESTAMP', timestamp)
        const consumptionRow = userConsumption.filter(
          f => f[dateRangeFilterSelection.value.objectName] === timestamp,
        )[0]
        if (consumptionRow) {
          successfulExecutions.push(consumptionRow.executions - consumptionRow.executionErrors)
          duration.push(consumptionRow.duration)
          failedExecutions.push(consumptionRow.executionErrors ? consumptionRow.executionErrors : 0)
        } else {
          successfulExecutions.push(0)
          duration.push(0)
          failedExecutions.push(0)
        }
      })
      console.log('RESULTS', { successfulExecutions, failedExecutions, duration })

      return { successfulExecutions, failedExecutions, duration }
    })

    const consumptionAverages = computed(() => {
      const { objectType, objectName } = store.getters.dateRangeFilter
      const userConsumptionData = store.getters[`snowflakeData/snowflakeUserConsumption${objectType}`]

      const avgConsumptionData = _(userConsumptionData)
        .groupBy(objectName)
        .map((m, date) => ({
          date,
          executions: _.meanBy(m, 'executions'),
          duration: _.meanBy(m, 'duration'),
          credits: _.meanBy(m, 'duration'),
          cost: _.meanBy(m, 'cost'),
        }))
        .orderBy('date')
        .value()

      const executionsData = avgConsumptionData.map(m => m.executions)
      const duration = avgConsumptionData.map(m => m.duration)
      const credits = avgConsumptionData.map(m => m.credits)
      const cost = avgConsumptionData.map(m => m.cost)

      return {
        executions: executionsData,
        duration,
        credits,
        cost,
      }
    })

    const $vuetify = getVuetify()

    const xAxis = computed(() => dateRangeFilterDays.value.javascript.map(m => m.toString()))

    const chartData = computed(() => [
      {
        name: 'Successful Executions',
        type: 'column',

        data: executions.value.successfulExecutions,
      },
      {
        name: 'Failed Executions',
        type: 'column',
        data: executions.value.failedExecutions,
      },
      {
        name: 'Execution Time',
        type: 'line',
        data: executions.value.duration,
      },
      {
        name: 'Avg Execution Time',
        type: 'area',
        data: consumptionAverages.value.duration,
      },
    ])

    const chartOptions = computed(() => ({
      colors: [
        colorLight($vuetify.theme.currentTheme.success),
        colorLight($vuetify.theme.currentTheme.error),
        $vuetify.theme.currentTheme.warning,
        colorLight($vuetify.theme.currentTheme.primary, 0.1),
      ],
      chart: {
        height: 350,
        type: 'line',
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '60%',
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0, 1],
        formatter: val => formatNumbers(val),
        style: {
          fontSize: '12px',
          colors: [$vuetify.theme.currentTheme.success, $vuetify.theme.currentTheme.error],
        },
      },
      stroke: {
        colors: ['white', 'white', $vuetify.theme.currentTheme.warning, $vuetify.theme.currentTheme.primary],
        width: [1, 1, 4, 4],
      },
      xaxis: {
        categories: xAxis.value,
        type: 'datetime',
      },
      yaxis: [
        {
          seriesName: 'Successful Executions',
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: $vuetify.theme.currentTheme.success,
          },
          labels: {
            formatter: value => kFormatter(value, 0),
            style: {
              colors: $vuetify.theme.currentTheme.success,
            },
          },
          title: {
            text: 'Executions',
            style: {
              color: $vuetify.theme.currentTheme.success,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        {
          seriesName: 'Successful Executions',
          show: false,
          labels: {
            formatter: value => kFormatter(value, 0),
          },
          title: {
            text: 'Failed Executions',
            style: {
              color: '#00E396',
            },
          },
        },
        {
          seriesName: 'Execution Time',
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: $vuetify.theme.currentTheme.warning,
          },
          labels: {
            formatter: value =>
              // eslint-disable-next-line implicit-arrow-linebreak
              context.root.$moment.duration(value, 'milliseconds').humanize({
                s: 60,
                m: 60,
                h: 24,
                d: 7,
                w: 4,
                M: 12,
              }),
            style: {
              colors: $vuetify.theme.currentTheme.warning,
            },
          },
          title: {
            text: 'Execution Time',
            style: {
              color: $vuetify.theme.currentTheme.warning,
            },
          },
        },
        {
          seriesName: 'Execution Time',
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            formatter: value =>
              // eslint-disable-next-line implicit-arrow-linebreak
              context.root.$moment.duration(value, 'milliseconds').humanize({
                s: 60,
                m: 60,
                h: 24,
                d: 7,
                w: 4,
                M: 12,
              }),
            style: {
              colors: '#FEB019',
            },
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'bottomRight', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 0,
          offsetX: 150,
        },
      },
      legend: {
        show: false,
        horizontalAlign: 'left',
        offsetX: 40,
      },
      responsive: [
        {
          breakpoint: 1650,
          options: {
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: '45%',
              },
            },
          },
        },
      ],
    }))

    // const chartOptions = {
    //   // colors: [$vuetify.theme.currentTheme.success],
    //   fill: {
    //     colors: [$vuetify.theme.currentTheme.success, $vuetify.theme.currentTheme.error],
    //     opacity: 0.3,
    //   },
    //   dataLabels: {
    //     enabled: true,
    //     formatter: val => formatNumbers(val),
    //     style: {
    //       fontSize: '12px',
    //       colors: [$vuetify.theme.currentTheme.success, $vuetify.theme.currentTheme.error],
    //     },
    //   },
    //   chart: {
    //     type: 'line',
    //     stacked: true,
    //     toolbar: {
    //       show: true,
    //     },
    //   },
    //   plotOptions: {
    //     bar: {
    //       borderRadius: 10,
    //       columnWidth: '35%',
    //       startingShape: 'rounded',
    //       endingShape: 'rounded',
    //     },
    //   },
    //   xaxis: {
    //     categories: xAxis,
    //     axisBorder: {
    //       show: false,
    //     },
    //     axisTicks: {
    //       show: false,
    //     },
    //   },
    //   yaxis: {
    //     labels: {
    //       formatter: value => kFormatter(value, 0),
    //     },
    //   },
    //   grid: {
    //     strokeDashArray: 1,
    //   },
    //   legend: {
    //     show: false,
    //   },
    //   stroke: {
    //     curve: 'smooth',
    //     width: 1,
    //     lineCap: 'round',
    //     colors: ['#fff'],
    //   },
    //   responsive: [
    //     {
    //       breakpoint: 1650,
    //       options: {
    //         plotOptions: {
    //           bar: {
    //             borderRadius: 10,
    //             columnWidth: '45%',
    //           },
    //         },
    //       },
    //     },
    //   ],
    // }

    return {
      dateRangeFilterDays,
      dateRangeFilterSelection,
      executions,
      chartOptions,
      chartData,

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
        mdiChartBar,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .total-profit-chart-col {
    @include ltr() {
      border-right: thin solid;
    }
    @include rtl() {
      border-left: thin solid;
    }
  }
}
@include theme--child(total-profit-chart-col) using ($material) {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    border-color: map-get($material, 'dividers');
  }
}
</style>

<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <user-bio-panel></user-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-row>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            sm="4"
          >
            <v-select
              v-model="dateRangeSelection"
              item-color="primary"
              :items="dateSelectionOptions"
              dense
              :prepend-inner-icon="icons.mdiFilterVariant"
              outlined
              filled
              rounded
              hide-details
              @change="changeDateFilter"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <user-tab-overview></user-tab-overview>
          </v-tab-item>

          <!-- <v-tab-item>
            <user-tab-security></user-tab-security>
          </v-tab-item>
          <v-tab-item>
            <user-tab-billings-plans :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"></user-tab-billings-plans>
          </v-tab-item>
          <v-tab-item>
            <user-tab-notifications></user-tab-notifications>
          </v-tab-item>
          <v-tab-item>
            <user-tab-connections></user-tab-connections>
          </v-tab-item> -->
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-dialog
      v-model="isPlanUpgradeDialogVisible"
      max-width="650"
    >
      <v-card class="py-8">
        <v-card-title class="justify-center text-h5 px-5">
          Upgrade Plan
        </v-card-title>
        <v-card-text class="text-center mt-n2 px-5">
          Choose the best plan for user.
        </v-card-text>
        <v-card-text class="d-flex align-center flex-wrap flex-sm-nowrap mt-5 px-15">
          <v-select
            v-model="selectedPlan"
            label="Choose Plan"
            :items="['HI THERE']"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details
            class="me-3"
          ></v-select>
          <v-btn
            color="primary"
            class="mt-3 mt-sm-0"
          >
            Upgrade
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-15 pt-8">
          <p class="font-weight-medium text--primary mb-2">
            User current plan is TEST plan
          </p>
          <div class="d-flex justify-space-between flex-wrap">
            <div class="user-pricing me-3">
              <sup class="primary--text">$</sup>
              <span class="text-5xl font-weight-semibold primary--text">TEST</span>
              <sub class="text-base font-weight-light">/ month</sub>
            </div>
            <v-btn
              color="error"
              outlined
              class="mt-3"
            >
              Cancel Subscription
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { buildRoleHierarchy } from '@/snowflake/snowflakeAdministration'
import store from '@/store'
import { useRouter } from '@core/utils'
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiBookmarkOutline, mdiFilterVariant, mdiLinkVariant, mdiLockOutline } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import { computed, provide, ref } from '@vue/composition-api'
import _ from 'lodash'
import UserBioPanel from './user-bio-panel/UserBioPanel.vue'
// eslint-disable-next-line lines-around-comment
// import UserTabBillingsPlans from './user-tab-billings-plans/UserTabBillingsPlans.vue'
// import UserTabConnections from './user-tab-connections/UserTabConnections.vue'
// import UserTabNotifications from './user-tab-notifications/UserTabNotifications.vue'
import UserTabOverview from './user-tab-overview/UserTabOverview.vue'

// import UserTabSecurity from './user-tab-security/UserTabSecurity.vue'

export default {
  components: {
    UserBioPanel,

    UserTabOverview,

    // UserTabSecurity,
    // UserTabBillingsPlans,
    // UserTabNotifications,
    // UserTabConnections,
  },
  setup() {
    const { route } = useRouter()
    const userTab = ref(null)
    const selectedPlan = ref('')
    const isPlanUpgradeDialogVisible = ref(false)

    const dateRangeFilter = computed(() => store.state.dateRangeFilter)
    const currentDateRangeSelection = computed(() => store.getters.dateRangeFilter)
    const dateRangeSelection = ref(currentDateRangeSelection.value.name)
    const dateSelectionOptions = computed(() => store.state.dateRangeFilter.map(m => m.name))
    const changeDateFilter = value => {
      store.commit('setDateRangeFilter', value)
    }
    provide('dateRangeSelection', dateRangeSelection)

    const userId = computed(() => route.value.path.split('user/')[1])

    provide('userId', userId)

    const userData = computed(() => {
      const snowflakeUsers = store.getters['snowflakeData/snowflakeUsersAndRoles']
      const roleHierarchy = buildRoleHierarchy()
      const allChildrenRoles = roleHierarchy.childRoles
      const filteredUsers = snowflakeUsers.filter(f => encodeURI(f.id) === userId.value)[0]
      let childRoles = []
      filteredUsers.roles.forEach(role => {
        childRoles = [...childRoles, ...allChildrenRoles[role]]
      })
      filteredUsers.childRoles = [...new Set(childRoles)]
      console.log('FilteredUsers', filteredUsers)
      console.log('allChildrenRoles', allChildrenRoles)
      const warehouses = []
      filteredUsers.childRoles.forEach(role => {
        store.state.snowflakeData.snowflakeWarehouses.forEach(warehouse => {
          warehouse.roles.forEach(warehouseRole => {
            if (warehouseRole === role && !warehouses.includes(warehouse.name)) {
              warehouses.push(warehouse.name)
            }
          })
        })
      })
      console.log('WAREHOUSES', warehouses)
      filteredUsers.warehouses = warehouses

      return filteredUsers
    })

    provide('userData', userData)

    const userConsumptionByFilter = computed(() => {
      const getUserData = store.getters['snowflakeData/snowflakeUserConsumptionByFilter']

      return getUserData.filter(f => encodeURI(f.id) === userId.value)[0]
    })

    provide('userConsumptionByFilter', userConsumptionByFilter)

    const consumptionAverages = computed(() => {
      const { objectType, objectName } = dateRangeFilter.value.filter(f => f.active)[0]
      const userConsumptionData = store.getters[`snowflakeData/snowflakeUserConsumption${objectType}`]

      const avgConsumptionData = _(userConsumptionData)
        .groupBy(objectName)
        .map((m, date) => ({
          date,
          executions: _.meanBy(m, 'executions'),
          duration: _.meanBy(m, 'duration'),
          credits: _.meanBy(m, 'duration'),
          cost: _.meanBy(m, 'cost'),
        }))
        .orderBy('date')
        .value()

      const executions = avgConsumptionData.map(m => m.executions)
      const duration = avgConsumptionData.map(m => m.duration)
      const credits = avgConsumptionData.map(m => m.credits)
      const cost = avgConsumptionData.map(m => m.cost)

      return {
        executions,
        duration,
        credits,
        cost,
      }
    })
    provide('consumptionAverages', consumptionAverages)

    const tabs = [
      { icon: mdiAccountOutline, title: 'Overview' },
      { icon: mdiLockOutline, title: 'Permissions' },
      { icon: mdiBookmarkOutline, title: 'Activity' },
      { icon: mdiLinkVariant, title: 'Connections' },
    ]

    return {
      userConsumptionByFilter,
      changeDateFilter,
      dateSelectionOptions,
      dateRangeSelection,
      tabs,
      userTab,
      selectedPlan,
      isPlanUpgradeDialogVisible,
      icons: {
        mdiFilterVariant,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
